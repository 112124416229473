<script>
export default {
    name: "Experience",
    
    data() {
        return {
            tabs: [
                {
                    id: "work",
                    icon: "fa-house-laptop",
                    text: this.$t("experience.work"),
                    link: "#work",
                    timeline: [
                        {
                            workplace: "Frontend Developer",
                            company: "Paymento Financial S.A.",
                            work_time: `2022.10 - ${this.$t("cv.until_now")}`
                        },
                        {
                            workplace: "Frontend Developer",
                            company: "CODEMA Software House",
                            work_time: "2020.10 - 2022.09"
                        },
                        {
                            workplace: "Junior WebDeveloper",
                            company: "Netventure Sp. z o.o",
                            work_time: "2018.07 – 2020.10"
                        },
                        {
                            workplace: "Freelancer",
                            company: "Euro Truck Simulator 2",
                            work_time: "2018.03"
                        }
                    ]
                },
                {
                    id: "education",
                    icon: "fa-graduation-cap",
                    text: this.$t("experience.education"),
                    link: "#education",
                    timeline: [
                        {
                            workplace: this.$t("experience.pedagogy"),
                            company: this.$t("experience.wskz"),
                            work_time: `2022.10 - ${this.$t("cv.until_now")}`
                        },
                        {
                            workplace: this.$t("experience.it_engineer"),
                            company: this.$t("experience.aeh"),
                            work_time: "2018.10 - 2022.07"
                        },
                        {
                            workplace: this.$t("experience.it_specialist"),
                            company: this.$t("experience.zs10"),
                            work_time: "2014.09 - 2018.05"
                        }
                    ]
                },
            ]
        };
    }
};
</script>

<template>
    <div
        id="experience"
        class="section js-animation"
    >
        <Title :title="$t('experience.title')" />
        
        <div class="experience">
            <ul
                class="nav nav-tabs"
                role="tablist"
            >
                <li
                    v-for="(tab, index) in tabs"
                    :key="`link_${index}`"
                    class="nav-item"
                    role="presentation"
                >
                    <button
                        :class="['nav-link', { 'active' : index === 0 }]"
                        data-bs-toggle="tab"
                        :data-bs-target="tab.link"
                        type="button"
                        role="tab"
                        :aria-controls="tab.id"
                        aria-selected="true"
                    >
                        <Icon :icon="tab.icon" />
                        {{ tab.text }}
                    </button>
                </li>
            </ul>
            <div class="tab-content mt-5">
                <div
                    v-for="(tab, index) in tabs"
                    :key="`tab_${index}`"
                    :class="['tab-pane fade', { 'show active' : index === 0 }]"
                    :id="tab.id"
                    role="tabpanel"
                    :aria-labelledby="`${tab.id}-tab`"
                >
                    <Timeline :timeline="tab.timeline" />
                </div>
            </div>

        </div>
    </div>
</template>
