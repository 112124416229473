<script>
import { mapGetters } from "vuex";

export default {
    name: "Footer",
    
    computed: {
        ...mapGetters({
            mode: "theme/getMode"
        })
    },
    
    data() {
        return {
            links: [
                {
                    text: this.$t("navbar.home"),
                    link: "#home"
                },
                {
                    text: this.$t("navbar.about"),
                    link: "#about"
                },
                {
                    text: this.$t("navbar.skills"),
                    link: "#skills"
                },
                {
                    text: this.$t("navbar.experience"),
                    link: "#experience"
                }
            ]
        };
    }
};
</script>

<template>
    <div class="footer js-animation">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-6 text-center">
                    <div class="footer-data mx-md-0 mx-auto">
                        <img
                            class="footer-img"
                            :src="assets(`/images/logo/logo-${this.mode}.png`)"
                            :alt="mode.split('-')[0]"
                        >
                        <p
                            class="mb-0"
                            v-text="$t('app.name')"
                        />
                        <p
                            class="mb-0"
                            v-text="$t('banner.subtitle')"
                        />
                    </div>
                </div>
                <div class="col-12 col-md-6 mt-3 mt-md-0">
                    <div class="footer-right mx-md-0 mx-auto">
                        <p
                            class="mb-1 mb-md-2"
                            v-text="$t('footer.shortcuts')"
                        />
                        <ul class="footer-list list-unstyled">
                            <li
                                v-for="(item, index) in links"
                                :key="index"
                            >
                                <a
                                    v-scroll-to="{ el: item.link, offset: -50 }"
                                    :href="item.link"
                                    v-text="item.text"
                                />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row mt-5">
                <p class="mb-0 text-center">
                    &copy; {{ $t("app.name") }}. {{ $t("app.reserved") }}
                </p>
            </div>
        </div>
    </div>
</template>
