<script>
import Navbar from "@/components/Navbar";
import Banner from "@/components/Banner";
import About from "@/components/About";
import Skills from "@/components/Skills";
import Experience from "@/components/Experience";
import Contact from "@/components/Contact";
import Footer from "@/components/Footer";
import Particles from "@/components/Particles";

export default {
    name: "Home",
    
    components: {
        Navbar,
        Banner,
        About,
        Skills,
        Experience,
        Contact,
        Footer,
        Particles
    },
    
    mounted() {
        window.addEventListener("scroll", this.scrollActive);
    },
    
    methods: {
        scrollActive() {
            const sections = document.querySelectorAll(".section");
            
            sections.forEach((current) => {
                const sectionHeight = current.offsetHeight;
                const sectionTop = current.offsetTop - 100;
                const sectionId = current.getAttribute("id");
    
                if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
                    document
                        .querySelector(".navbar-nav .nav-item a[href*=" + sectionId + "]")
                        .classList.add("active");
                } else {
                    document
                        .querySelector(".navbar-nav .nav-item a[href*=" + sectionId + "]")
                        .classList.remove("active");
                }
            });
        }
    }
};
</script>

<template>
    <div>
        <div
            id="home"
            class="position-relative section js-animation"
        >
            <Navbar />
            <Banner />
            <Particles />
        </div>
        
        <div class="container">
            <About />
            <Skills />
            <Experience />
            <Contact />
        </div>
        
        <Footer />
    </div>
</template>
