<script>
import axios from "axios";
import { useVuelidate  } from "@vuelidate/core";
import { required, minLength, maxLength, email } from "@vuelidate/validators";
import { CONTACT } from "@/http/url";

export default {
    name: "Contact",

    setup() {
        return { v$: useVuelidate() };
    },

    data() {
        return {
            full_name: "",
            mail_from: "",
            subject: "",
            message: "",
            loading: false,
            isError: false,
        };
    },

    methods: {
        async send() {
            this.loading = true;
            this.v$.$touch();

            try {
                this.isError = false;
                const { status } = await axios.post(CONTACT, {
                    full_name: this.full_name,
                    mail_from: this.mail_from,
                    subject: this.subject,
                    message: this.message.replace("\n", "<br>")
                });

                if (status === 200) {
                    this.full_name = "";
                    this.mail_from = "";
                    this.subject = "";
                    this.message = "";
                    this.v$.$reset();
                }
            } catch (e) {
                this.isError = true;
            } finally {
                this.loading = false;
            }
        }
    },

    validations: {
        full_name: {
            required,
            minLength: minLength(3)
        },
        mail_from: {
            required,
            email
        },
        subject: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(255)
        },
        message: {
            required,
            minLength: minLength(10),
            maxLength: maxLength(10000)
        }
    },
};
</script>

<template>
    <div
        id="contact"
        class="section js-animation"
    >
        <Title :title="$t('contact.title')" />

        <form
            class="form-contact row g-3"
            novalidate
            @submit.prevent="send"
        >
            <div class="col-12">
                <label
                    v-text="$t('contact.full_name')"
                    for="full_name"
                    class="form-label"
                />
                <input
                    v-model="full_name"
                    type="text"
                    :class="['form-control', { 'is-invalid': v$.full_name.$error }]"
                    id="full_name"
                    required
                    autocomplete="off"
                    @input="v$.full_name.$touch()"
                >
                <div
                    v-if="v$.full_name.$dirty && v$.full_name.required.$invalid"
                    class="invalid-feedback"
                >
                    {{ $t("validate.required_{name}", { name: $t('contact.full_name') }) }}
                </div>
                <div
                    v-if="v$.full_name.$dirty && v$.full_name.minLength.$invalid"
                    class="invalid-feedback"
                >
                    {{ $t("validate.min_length_{name}_{length}", { name: $t('contact.full_name'), length: v$.full_name.minLength.$params.min }) }}
                </div>
            </div>

            <div class="col-12">
                <label
                    v-text="$t('contact.email')"
                    for="email"
                    class="form-label"
                />
                <input
                    v-model="mail_from"
                    type="text"
                    :class="['form-control', { 'is-invalid': v$.mail_from.$error }]"
                    id="email"
                    required
                    autocomplete="off"
                    @input="v$.mail_from.$touch()"
                >
                <div
                    v-if="v$.mail_from.$dirty && v$.mail_from.required.$invalid"
                    class="invalid-feedback"
                >
                    {{ $t("validate.required_{name}", { name: $t('contact.email') }) }}
                </div>
                <div
                    v-if="v$.mail_from.email.$invalid"
                    class="invalid-feedback"
                >
                    {{ $t("validate.invalid_{name}", { name: $t('contact.email') }) }}
                </div>
            </div>

            <div class="col-12">
                <label
                    v-text="$t('contact.subject')"
                    for="email"
                    class="form-label"
                />
                <input
                    v-model="subject"
                    type="text"
                    :class="['form-control', { 'is-invalid': v$.subject.$error }]"
                    id="subject"
                    required
                    autocomplete="off"
                    @input="v$.subject.$touch()"
                >
                <div
                    v-if="v$.subject.$dirty && v$.subject.required.$invalid"
                    class="invalid-feedback"
                >
                    {{ $t("validate.required_{name}", { name: $t('contact.subject') }) }}
                </div>
                <div
                    v-if="v$.subject.$dirty && v$.subject.minLength.$invalid"
                    class="invalid-feedback"
                >
                    {{ $t("validate.min_length_{name}_{length}", { name: $t('contact.subject'), length: v$.subject.minLength.$params.min }) }}
                </div>
                <div
                    v-if="v$.subject.$dirty && v$.subject.maxLength.$invalid"
                    class="invalid-feedback"
                >
                    {{ $t("validate.max_length_{name}_{length}", { name: $t('contact.subject'), length: v$.subject.maxLength.$params.max }) }}
                </div>
            </div>

            <div class="col-12">
                <label
                    v-text="$t('contact.message')"
                    for="email"
                    class="form-label"
                />
                <textarea
                    v-model="message"
                    id="message"
                    type="text"
                    :class="['form-control', { 'is-invalid': v$.message.$error }]"
                    @input="v$.message.$touch()"
                />
                <div
                    v-if="v$.message.$dirty && v$.message.required.$invalid"
                    class="invalid-feedback"
                >
                    {{ $t("validate.required_{name}", { name: $t('contact.message') }) }}
                </div>
                <div
                    v-if="v$.message.$dirty && v$.message.minLength.$invalid"
                    class="invalid-feedback"
                >
                    {{ $t("validate.min_length_{name}_{length}", { name: $t('contact.message'), length: v$.message.minLength.$params.min }) }}
                </div>
                <div
                    v-if="v$.message.$dirty && v$.message.maxLength.$invalid"
                    class="invalid-feedback"
                >
                    {{ $t("validate.max_length_{name}_{length}", { name: $t('contact.message'), length: v$.message.maxLength.$params.max }) }}
                </div>
            </div>

            <div class="col-12 text-center">
                <button
                    class="btn btn-contact"
                    :disabled="v$.$invalid || loading"
                >
                    <div
                        v-if="loading"
                        class="spinner-border text-secondary"
                        role="status"
                    >
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <span v-else>
                        {{ $t('contact.send') }}
                    </span>
                </button>
            </div>
        </form>

        <div
            v-if="isError"
            class="form-error my-5"
        >
            {{ $t('contact.error') }}
        </div>
    </div>
</template>
