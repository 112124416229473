<script>
import { mapGetters } from "vuex";

export default {
    name: "About",

    computed: {
        ...mapGetters({
            lang: "lang/getLang",
        }),
    },
    
    data() {
        return {
            info: [
                {
                    number: `${this.calculateYear(2018, 7)}+`,
                    text: `${this.$t("about.years")}<br>${this.$t("about.experience")}`
                },
                {
                    number: "20+",
                    text: `${this.$t("about.projects")}<br>${this.$t("about.completed")}`
                }
            ],
            publicPath: process.env.BASE_URL,
        };
    },
    
    methods: {
        calculateYear(year, month) {
            const formatDate = new Date(year, month);
            const now = new Date();
            let diff = (now.getTime() - formatDate.getTime()) / 1000;
            diff /= (60 * 60 * 24);
            return Math.abs(Math.round(diff/365.25));
        }
    }
};
</script>

<template>
    <div
        id="about"
        class="about section js-animation"
    >
        <Title :title="$t('about.title')" />
        
        <div class="row">
            <div class="col-12 col-lg-6">
                <div class="about-content-img">
                    <img
                        class="about-img"
                        :src="assets(`/images/me/me-about.jpg`)"
                        alt="Szymon Gołębiewski"
                    >
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <p
                    class="mb-4"
                    v-text="$t('about.about')"
                />
                
                <div class="about-data">
                    <div
                        class="about-data-item"
                        v-for="(item, index) in info"
                        :key="`info_${index}`"
                    >
                        <p v-html="item.number" />
                        <p v-html="item.text" />
                    </div>
                </div>
                
                <div class="d-flex gap-2 justify-content-around my-3">
                    <router-link
                        :to="{ name: 'CV' }"
                        class="btn btn-banner"
                    >
                        {{ $t("about.cv") }}
                        <Icon :icon="'fa-image-portrait ms-2'" />
                    </router-link>
    
                    <a
                        :href="`${publicPath}cv/CV-Szymon_Gołębiewski-${lang.toUpperCase()}.pdf`"
                        class="btn btn-banner"
                        :download="`CV-Szymon_Gołębiewski-${lang.toUpperCase()}`"
                    >
                        {{ $t("about.download") }}
                        <Icon :icon="'fa-file-arrow-down ms-2'" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>