<script>
import { mapGetters } from "vuex";
import vue3StarRatings from "vue3-star-ratings";

export default {
    name: "Skills",
    
    components: {
        vue3StarRatings
    },
    
    computed: {
        ...mapGetters({
            skills: "skills/getSkills"
        })
    }
};
</script>

<template>
    <div
        id="skills"
        class="section js-animation"
    >
        <Title :title="$t('skills.title')" />
        
        <div class="row gap-y-30">
            <div
                v-for="(skill, index) in skills"
                :key="`skill_${index}`"
                :class="['col-12 col-sm-6 col-md-4 col-lg-3']"
            >
                <div class="d-flex flex-column align-items-center">
                    <img
                        :src="skill.img"
                        :alt="skill.name"
                    >
                    <p
                        v-text="skill.name"
                        class="mb-2"
                    />
                    <vue3StarRatings
                        v-model="skill.rating"
                        :showControl="false"
                        :disableClick="true"
                        starSize="24"
                        class="my-0 py-0"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
