<script>
import {
    mapGetters,
    mapMutations
} from "vuex";

export default {
    name: "Navbar",
    
    computed: {
        ...mapGetters({
            lang: "lang/getLang",
            languages: "lang/getLanguages",
            mode: "theme/getMode"
        })
    },
    
    data() {
        return {
            show: false,
            navbar: [
                {
                    text: this.$t("navbar.home"),
                    link: "#home"
                },
                {
                    text: this.$t("navbar.about"),
                    link: "#about"
                },
                {
                    text: this.$t("navbar.skills"),
                    link: "#skills"
                },
                {
                    text: this.$t("navbar.experience"),
                    link: "#experience"
                },
                {
                    text: this.$t("navbar.contact"),
                    link: "#contact"
                }
            ]
        };
    },
    
    created() {
        window.addEventListener("scroll", this.scrollNav);
    },
    
    methods: {
        changeLanguage(flag) {
            this.setVisible(true);
            this.setLang(flag);
            this.$i18n.locale = flag;
    
            setTimeout(() => {
                this.setVisible(false);
            }, 1500);
        },
    
        toggleMode(mode) {
            this.setVisible(true);
            
            setTimeout(() => {
                this.setMode(mode === "dark-theme" ? "light-theme" : "dark-theme");
                
                setTimeout(() => {
                    this.setVisible(false);
                }, 0);
            }, 1500);
        },
        
        scrollNav() {
            const navbar = document.querySelector(".navbar");
            if (navbar) window.scrollY > 50 ? navbar.classList.add("navbar-scroll") : navbar.classList.remove("navbar-scroll");
        },

        hideMobileMenu() {
            if (this.show) {
                document.querySelector(".navbar-toggler").click();
            }
        },

        changeTitle(title) {
            document.title = (title === this.$t("navbar.home")) ? this.$t("app.name") : `${this.$t("app.name")} - ${title}`;
        },
        
        ...mapMutations({
            setLang: "lang/setLang",
            setMode: "theme/setMode",
            setVisible: "preload/setVisible"
        })
    }
};
</script>

<template>
    <nav
        ref="nav"
        :class="['navbar navbar-expand-lg fixed-top', { 'navbar-show-menu' : show }]"
    >
        <div class="container">
            <a
                class="navbar-brand"
                href="#"
            >
                <img
                    :src="assets(`/images/logo/logo-${this.mode}.png`)"
                    :alt="mode.split('-')[0]"
                >
            </a>
            <button
                class="navbar-toggler collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                @click="show = !show"
            >
                <span class="toggler-icon top-bar"></span>
                <span class="toggler-icon middle-bar"></span>
                <span class="toggler-icon bottom-bar"></span>
            </button>
            
            <div
                class="collapse navbar-collapse"
                id="navbarSupportedContent"
            >
                <ul class="navbar-nav ms-auto">
                    <li
                        v-for="(item, index) in navbar"
                        :key="index"
                        class="nav-item"
                    >
                        <a
                            v-scroll-to="{ el: item.link, offset: -50 }"
                            :class="['nav-link', { 'active' : index === 0 }]"
                            :href="item.link"
                            @click="hideMobileMenu(); changeTitle(item.text)"
                        >
                            {{ item.text }}
                        </a>
                    </li>
                    <li class="nav-item dropdown">
                        <a
                            class="nav-link dropdown-toggle flag-container"
                            href="#"
                            id="navbarDropdown"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <img
                                class="flag"
                                :src="assets(`/images/flag/${this.lang}.jpg`)"
                                :alt="lang"
                            >
                            <span
                                v-text="lang"
                                class="text-uppercase fw-bold"
                            />
                        </a>
                        <ul
                            class="dropdown-menu"
                            aria-labelledby="navbarDropdown"
                        >
                            <li
                                v-for="(lang, index) in languages"
                                :key="`lang_${index}`"
                            >
                                <a
                                    class="dropdown-item text-uppercase flag-container"
                                    href="!#"
                                    @click.prevent="changeLanguage(lang)"
                                >
                                    <img
                                        class="flag"
                                        :src="assets(`/images/flag/${lang}.jpg`)"
                                    >
                                    <span
                                        v-text="lang"
                                        class="text-uppercase"
                                    />
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li
                        class="nav-item"
                    >
                        <a
                            class="nav-link"
                            href="#"
                            @click.prevent="toggleMode(mode)"
                        >
                            <Icon :icon="mode === 'dark-theme' ? 'fa-sun' : 'fa-moon'" />
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>
