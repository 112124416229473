<script>
export default {
    name: "Banner",
};
</script>

<template>
    <div class="banner">
        <div class="container">
            <div class="banner-content">
                <div class="row">
                    <div class="col-12 col-lg-7 align-self-lg-center order-lg-0 order-1">
                        <div class="banner-content-data">
                            <h1 class="banner-content-data-title">
                                {{ $t("banner.title") }}
                            </h1>
                            <div class="banner-content-data-subtitle">
                                {{ $t("banner.subtitle") }}
                            </div>
                            <p class="banner-content-data-description">
                                {{ $t("banner.description") }}
                            </p>
                            <a
                                href="#contact"
                                class="btn btn-banner"
                            >
                                {{ $t("banner.contact") }}
                                <Icon :icon="'fa-paper-plane ms-2'" />
                            </a>
                        </div>
                    </div>
                    <div class="col-12 col-lg-5  order-lg-1 order-0">
                        <div class="w-100 h-100 d-flex justify-content-center align-items-center pb-lg-0 pb-5">
                            <div class="blob" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
